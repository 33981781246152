import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpcallService {

  // private URL_  = 'http://192.168.8.148:80/~capeles/CRANEeH/RESTAPI/public/users-management/users-management.php';
  // private URL_ = 'http://192.168.43.121:8081/users';
  // private URL_ = 'https://api.craneeh.com/v1/users';
  private URL_ = 'https://health-craneeh.craneeh.com/v1/users';

  constructor(private http: HttpClient) { }

  // Update Password. Request new password
  // ---------------
  updatePasswordByRequest(body): Observable<any> {
    return this.http.post<any>(this.URL_ + '/forgotpassword', body);
  }
  // ---------------

  // Confirm Account
  confirmAccount(body): Observable<any> {
    debugger;
    return this.http.post<any>(this.URL_ + '/confirmaccount', body);
  }
  //
}
