import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpcallService } from 'src/app/services/httpcall.service';
import { catchError, flatMap, mergeMap, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-confirmed-account',
  templateUrl: './confirmed-account.component.html',
  styleUrls: ['./confirmed-account.component.css']
})
export class ConfirmedAccountComponent implements OnInit {

  email: any;
  result: any;

  constructor(private route: ActivatedRoute, private httpCall: HttpcallService) {
    this.route.params.subscribe(params => this.email = params.email);
    this.result = '';

    /*this.route.params.pipe(
      finalize(() => this.confirmAccount(this.email))
    ).subscribe(
      response => {
        this.email = response;
        // console.log(response);
      },
      error => {
        console.log(error);
      }
    );*/

    /*this.route.params.subscribe(
      params => {
        this.email = params.email;
        const body = {
          email: this.email
        };
        this.confirmAccount(body);
      });*/
  }

   // http://localhost:8080/~capeles/CRANEeH/RESTAPI/public/users-management/users-management.php/confirmaccount
  ngOnInit() {
    // console.log(this.email);
        const body = {
          email: this.email
        };
        this.confirmAccount(body);
  }

  confirmAccount(body) {
    debugger;
    this.httpCall.confirmAccount(body).subscribe(
      response => {
        console.log(response);
      }
    );
  }

}
