import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmedAccountComponent } from './components/confirmed-account/confirmed-account.component';
import { OmronAccessComponent } from './components/omron-access/omron-access.component';

const routes: Routes = [
  {
    path: 'forgot-password/:email',
    component: ForgotPasswordComponent
  },
  {
    path: 'confirmed-account/:email',
    component: ConfirmedAccountComponent
  },
  {
    path: 'omron-access/:result',
    component: OmronAccessComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
