import { Injectable } from '@angular/core';
import * as bcryptjs from 'bcryptjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  hashPass(password) {

    // Need to work with this later
    // var randomS = Math.floor(Math.random() * 5) + 10;
    const salt = bcryptjs.genSaltSync(12);
    const hash = bcryptjs.hashSync(password, salt);
    return hash;

  }
}
