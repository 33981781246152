import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { HttpcallService } from 'src/app/services/httpcall.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  isLinear = true;
  isEditable: true;
  firstFormGroup: FormGroup;
  email: string;
  doneMessage: string;

  validation_messages = {
    'newpassword': [
      { type: 'minlength', message: 'Password must be minimum 8 characters.' },
      { type: 'required', message: 'Password is required.' },
      { type: 'pattern', message: 'Password must contain combination of upper and ' +
    'lower case letters, numbers and special characters (@,$,!,%,*,?,&).' }
      ],
    'confirmnewpassword': [
      { type: 'required', message: 'Confirm New Password is required.' },
      { type: 'equalTo', message: 'Confirm New Password is not same as Password.' }
      ]
  };

  constructor(private fb: FormBuilder, private helper: HelperService, private httpCall: HttpcallService,
    private route: ActivatedRoute) {
      // Get email from post request
      debugger;
    this.route.params.subscribe(params => this.email = params.email);

    this.firstFormGroup = fb.group({
      newpassword: [
        '',
        [
          Validators.required,
          Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`)
         ]
      ],
      confirmnewpassword: [
        '',
        [
          Validators.required,
          Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`),
          this.equalto('newpassword')
         ]
      ]
    });
   }

   equalto(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const input = control.value;
        const isValid = control.root.value[field_name] === input;
        if (!isValid) {
          return {'equalTo': {isValid}};
        } else {
            return null;
        }
    };
  }

  ngOnInit() {

  }

  onSubmit () {
    debugger;
    const val = this.firstFormGroup.value;
    const tempPass = this.helper.hashPass(val.confirmnewpassword);

    const body = {
      email: this.email,
      pass: tempPass
    };

    this.httpCall.updatePasswordByRequest(body)
    .subscribe (
      response => {
        this.doneMessage = response['message'];
        // console.log(response['message']);
      },
      error => {
        console.log(error);
      }
    );

  }

}
