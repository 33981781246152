import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-omron-access',
  templateUrl: './omron-access.component.html',
  styleUrls: ['./omron-access.component.css']
})
export class OmronAccessComponent implements OnInit {

  result: any;

  constructor(private route: ActivatedRoute) {
    debugger;
    this.route.params.subscribe(params => {
      // call API again
      this.result = params.result;
    }
    , error => {
      console.log(error);
    });
   }

  ngOnInit() {

  }

}
